<template>
  <surroundBox :name="description" :unit="unit" desc="粪污收纳重量">
    <template slot="content">
      <div class="waste-echart" style="width:100%;height:100%"></div>
    </template>
  </surroundBox>

</template>

<script>
import * as echarts from 'echarts'
import surroundBox from "../surroundBox/surroundBox";
export default {
  props: {
    currentFarm: Object,
  },
  components: {
    surroundBox,
  },
  name: "waste",
  data() {
    return {
      description: "粪污收纳",
      chart: null,
      unit: "",
      currIndexRecommend: Number,
      datas: [],
      type: "",
      chooselist: [
        {text: "日", id: "1", isSelect: false, type: "day"},
        {text: "月", id: "2", isSelect: false, type: "month"},
        {text: "年", id: "3", isSelect: true, type: "year"},
      ],
    }
  },
  mounted() {
    this.getStatics().then(() => {
      this.initCharts()
    });

    window.addEventListener('resize', () => {
      this.chart && this.chart.resize()
    })
  },
  methods: {
    checkInfo(currIndexRecommend) {
      let id = this.chooselist[currIndexRecommend].id;
      let comboList = this.chooselist;
      for (let i = 0; i < comboList.length; i++) {
        comboList[i].isSelect = i === currIndexRecommend;
      }
      this.chooselist = comboList;
      this.type = this.chooselist[currIndexRecommend].type;
      this.getStaticss().then(() => {
        this.initChart();
      });
    },
    initCharts() {
      if (this.chart == null) {
        this.chart = echarts.init(document.querySelector(".waste-echart"))
      }
      let option = {
        tooltip: {
          show: true,
          trigger: "axis",
          backgroundColor: "rgba(12, 12, 13, 0.4)",
          borderColor: "rgba(12, 12, 13, 0.4)",
          borderWidth: 0,
          textStyle: {
            color: "#FFFFFF",
          },
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "#7E91AE",
              type: "solid",
            },
          },
          formatter: (params, ticket) => {
            let d = params[0];
            let icon = `<div style="display:flex;">${d.axisValue}年&nbsp;&nbsp;&nbsp;&nbsp;${d.data}${this.unit}</div>`;
            return `<div style="font-size:12px;font-weight:400;">${icon}</div>`;
          },
        },
        grid: {
          containLabel: true,
          top: 32,
          left: 25,
          right: 32,
          bottom: 16,
        },
        xAxis: {
          boundaryGap: false,
          axisLabel: {
            interval: 0, //横轴信息全部显示
            rotate: 0, //0度角倾斜显示
            show: true,
            fontSize: 12,
            color: "#DAE1EB",
          },
          axisLine: {
            lineStyle: {
              color: "#586F91",
            },
          },
          axisTick: {
            show: false,
          },
          data: this.datas.map((item) => item.time),
        },
        yAxis: {
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: "#DAE1EB",
            fontSize: 12,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ["#586F91"],
              type: "solid",
            },
          },
        },
        series: [{
          type: 'line',
          smooth: true,
          showSymbol: false,
          emphasis: {
            focus: 'series'
          },
          data: this.datas.map((item) => item.val),
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#ffcf36'
              },
              {
                offset: 1,
                color: 'rgba(34,255,219,0.00)'
              }
            ])
          },
          lineStyle: {
            width: 1,
            color: '#ffcf36'
          }
        },],

      }
      this.chart.setOption(option)
    },
    getStatics() {
      this.datas = [];
      return this.$post(this.$api.STATICS_MANAGE.GET2, {
        resourceTypeId: 129,
      }).then((res) => {
        res.forEach((item) => {
          this.datas.push({
            time: item.dimensionName,
            val: item.content,
          });
        });
        this.unit = res[0].contentUnit;
      });
    },
    getStaticss() {
      this.datas = [];
      return this.$post(this.$api.STATICTICS_MAP.AMOUNT, {
        type: this.type,
      }).then((res) => {
        let timeLine = res.timeLine;
        for (let key in timeLine) {
          this.datas.push({
            time: key,
            val: timeLine[key],
          });
        }
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {
    })
  }
}
</script>


